import "../../../cabinet.sass";
import React, { useEffect, useState, useRef, useContext } from "react";
import InputPhoto from "../../InputPhoto";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { token } from "../../../../App";
import { customStyles2 } from "../../../../components/header/headerTop/headerSelects/selects";
import { DropdownIndicator } from "../../../../components/header/headerTop/headerSelects/selects";
import Input from "../../../../UI/Input";
import { CalendarIcon } from "../../../../components/icons/CalendarIcon";
import AuthContext from "../../../../store/auth-context";
import { link } from "../../../../store/host-store";
import CabinetSocToggle from "../../../cabinetInfo/CabinetSocToggle";
import ruble from "../../../../assets/images/icons/ruble.svg";
import DeviceContext from "../../../../store/device-context";
import ModalsContext from "../../../../store/modals-context";
import { useDispatch, useSelector } from "react-redux";
import calendar from "../../../../store/redux/calendar";
import { calendarAction } from "../../../../store/redux";
import {useNavigate} from "react-router-dom";
import CreateAdsCity from "../../../../adRole/ads/createAds/CreateAdsCity";
import {RegistratorBlock} from "../../registratorBlock/registratorBlock";
import {RegistrarActivity} from "../../activityRegistrar/RegistrarActivity";
import moment from "moment";
import Select from "react-select";
import {offerStatus} from "./offerStatus";


const typeInput = {
    0: "техники",
    1: "бригады",
    2: "недвижимости",
};

export const convertTypeInputToString = (type) => typeInput[type];

const CreateForm = (props) => {
    const modal = useContext(ModalsContext);
    const ctx = useContext(AuthContext);
    const device = useContext(DeviceContext);
    const { type } = props;
    const [elementId, setElementId] = useState([0]);
    const [arrayCityId, setArrayCityId] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [images, setImages] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [cityId, setCityIid] = useState("");
    const [category, setCategory] = useState(null);
    const [categoryParent, setCategoryParent] = useState(null);
    const [haveSubCategories, setHaveSubCategories] = useState(0);
    const [getCategoryFetch, setCategoryFetch] = useState([]);
    const [weekend, setWeekend] = useState(true);
    const [errors, setErrors] = useState({});
    const [registratorData, setRegistratorData] = useState({
        tel: '',
        name: '',
    });
    const [imagesAvatar, setImagesAvatar] = useState([]);
    const [registrarActivity, setRegistrarActivity] = useState(0);
    const registrarDate = moment().add(registrarActivity, 'days').format('YYYY-MM-DD hh:mm:ss');
    const [activeStatus, setActiveStatus] = useState();

    console.log(arrayCityId)
    function showErrors(errorList) {
        setErrors("");
        errorList.map((error) => {
            switch (error.field) {
                case "name":
                    setErrors((prevState) => ({ ...prevState, name: error }));
                    break;
                case "model":
                    setErrors((prevState) => ({ ...prevState, model: error }));
                    break;
                case "about":
                    setErrors((prevState) => ({ ...prevState, about: error }));
                    break;
                case "category_id":
                    setErrors((prevState) => ({ ...prevState, category: error }));
                    break;
                case "city_id":
                    setErrors((prevState) => ({ ...prevState, city_id: error }));
                    break
                case "contactPhone":
                    setErrors((prevState) => ({ ...prevState, contactPhone: error }));
                    break;
                default:
            }
        });
    }

    const getCity = (city) => {
        setCityIid(city?.value)
        setDisabled(false);
    };
    const dispatch = useDispatch();
    const dates = useSelector((state) => state.calendar.calendarBack);
    const navigate = useNavigate();

    useEffect(() => {
        // Работаем в выходные boolean
        dispatch(calendar.actions.workOnWeekends(weekend));
    }, [weekend]);

    console.log(dates);

    useEffect(() => {
        setCategoryParent(null);
        setHaveSubCategories(0);
    }, [type]);

    const loadCategoryParent = (inputValue, callback) => {
        let options = [];
        axios
            .create({
                baseURL: `${link}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            })
            .get(`category?filter[type]=${type}&filter[depth]=1&pagination=0&sort=title`)
            .then((response) => {
                // console.log('category?filter[type]', response.data.data);
                response.data.data.forEach((permission) => {
                    options.push({
                        label: permission.title,
                        value: permission.id,
                    });
                    callback(options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase())));
                });
            });
    };

    const loadCategory = (inputValue, callback) => {
        // запрашиваем список для "вида техники"
        if (categoryParent == null) {
            return;
        }
        axios
            .create({
                baseURL: `${link}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            })
            .get(`category?filter[parent_id]=${categoryParent.value}&pagination=0&sort=title`)
            .then(({ data }) => {
                console.log("category?filter", data.data.length);
                setHaveSubCategories(data.data);
                console.log(data.data);
                callback(
                    data.data
                        .map(({ title, id }) => ({
                            label: title,
                            value: id,
                        }))
                        .filter(({ label }) => label.toLowerCase().includes(inputValue.toLowerCase()))
                );
            });
    };

    const inputName = useRef();
    const inputModel = useRef();
    const inputPriceHour = useRef();
    const inputPriceDay = useRef();
    const inputAbout = useRef();
    const inputQuantity = useRef();

    const createAds = (event) => {
        event.preventDefault();
        let cities = [...new Set(arrayCityId.filter(Boolean))];
        const formData = new FormData();
        let category_id = category === null ? "" : category.value;
        let selected_images = images.length === 0 ? null : images;

        if (+type === 2 && haveSubCategories.length === 0) {
            category_id = categoryParent.value;
        }

        formData.append("about", inputAbout.current.value);
        formData.append("type", type);
        formData.append("category_id", category_id);
        formData.append("model", inputModel.current.value);
        formData.append("name", inputName.current.value);
        formData.append("price_1", inputPriceHour.current.value);
        formData.append("price_2", inputPriceDay.current.value);
        if(cities.length > 0){
            cities.map(item => formData.append('cities[]', item))
        }
        {
            selected_images === null
                ? formData.append("image", null)
                : selected_images.forEach((file) => {
                      formData.append("image[]", file);
                  });
        }
        formData.append("active", 1);
        formData.append("user_id", ctx.isUserId);
        formData.append("quantity", inputQuantity.current.value);
        formData.append("work_on_weekend", weekend ? 1 : 0);
        formData.append("schedule", JSON.stringify(dates));
        if(ctx.userData?.account?.role === 'registrar'){
            formData.append('contactName', registratorData.name)
            formData.append('contactPhone', registratorData.tel)
            formData.append('contactAvatar', imagesAvatar[0])
            if(registrarActivity !== 0){
                formData.append('final_date', registrarDate)
            }
        }
        if(activeStatus !== undefined){
            formData.append('type_activity', activeStatus?.value)
        }

        const sendRequest = async () => {
            setDisabled(true);
            await axios
                .post(`${link}/object`, formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                        Authorization: token,
                    },
                })
                .then(({ data }) => {
                    if (data.success) {
                        navigate('/lc/advertisement')
                        dispatch(calendarAction.cancelCalendar());
                    } else {
                        console.log("не удалось");
                        showErrors(data.data);
                        setDisabled(false);
                    }
                })
                .catch((err) => console.log(err));
            await ctx.setRerender((prev) => !prev);
            // bufferArr = [];
        };
        sendRequest();
    };


    useEffect(() => {
        let linkPrice;
        if (categoryParent?.value && category?.value) {
            linkPrice = `/category/${category?.value}`;
        } else if (categoryParent?.value && !category?.value) {
            linkPrice = `/category/${categoryParent?.value}`;
        } else {
            linkPrice = `/category?filter[type]=${props.type}&filter[depth]=0`;
        }
        // /category?filter[type]=0&filter[depth]=0
        fetch(`${link}${linkPrice}`, {
            method: "GET",
            headers: {
                Accept: "application/json,",
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.success) {
                    if (linkPrice.includes(`depth`)) {
                        setCategoryFetch(res.data[0]);
                    } else {
                        console.log(res.data);
                        setCategoryFetch(res.data);
                    }
                } else {
                    if (res.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/");
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [category?.value, categoryParent?.value, props.type]);

    useEffect(() => {
        // Очистить значения если переключили на другой тип
        setCategoryParent(null);
        setCategory(null);
    }, [props.type]);

    return (
        <form className="form_cabinet_create">
            <div className="create_ads__center">
                <div className="create_ads__box">
                    {ctx.userData?.account?.role === 'registrar' &&
                    <RegistratorBlock
                        setRegistratorData={setRegistratorData}
                        registratorData={registratorData}
                        setImagesAvatar={setImagesAvatar}
                        id={'registrar'}
                        imagesAvatar={imagesAvatar}
                        error={errors}
                    />
                    }
                    <div className="create_ads_t_block create_ads_border">
                        <div className="advers___title">Характеристики:</div>
                        <div className="create__ads_top__block create__ads_block">
                            <div className={`input_wrap  ${+type === 2 ? "input_wrap_100" : ""}`}>
                                <AsyncSelect
                                    key={type}
                                    components={{ DropdownIndicator }}
                                    placeholder={type !== 1 ? "Тип " + convertTypeInputToString(type) : 'Сфера деятельности'}
                                    cacheOptions
                                    defaultOptions
                                    onChange={setCategoryParent}
                                    value={categoryParent}
                                    styles={customStyles2}
                                    loadOptions={loadCategoryParent}
                                    noOptionsMessage={() => ""}
                                />
                            </div>

                            <div
                                className={`input_wrap ${haveSubCategories.length > 0 || +type !== 2 ? "" : "dn"} ${
                                    haveSubCategories.length > 0 && +type === 2 ? "input_wrap_100" : ""
                                }`}>
                                <AsyncSelect
                                    key={categoryParent?.value}
                                    components={{ DropdownIndicator }}
                                    placeholder={type !== 1 ? "Вид " + convertTypeInputToString(type) : 'Вид деятельности'}
                                    cacheOptions
                                    onChange={setCategory}
                                    defaultOptions
                                    styles={customStyles2}
                                    loadOptions={loadCategory}
                                    noOptionsMessage={() => "Выберите тип " + convertTypeInputToString(type)}
                                />
                                <p className="error">{errors.category?.message}</p>
                            </div>

                            <div className={`input_wrap  ${+type === 1 ? "input_wrap_" : "dn"}`}>
                                <Select
                                    key={type}
                                    components={{ DropdownIndicator }}
                                    onChange={setActiveStatus}
                                    placeholder="Статус"
                                    options={offerStatus}
                                    value={activeStatus}
                                    styles={customStyles2}
                                    noOptionsMessage={() => ""}
                                />
                            </div>

                            <div className={`input_wrap input_wrap_100 ${+type === 0 ? "" : "dn"}`}>
                                <Input className="input" type="text" placeholder="Модель" ref={inputModel}></Input>
                                <p className="error">{errors.model?.message}</p>
                            </div>
                            <div className={`input_wrap  ${+type === 1 ? "" : "dn"}`}>
                                <Input
                                    className="input"
                                    type="number"
                                    placeholder="Кол-во людей"
                                    ref={inputQuantity}></Input>
                            </div>
                        </div>
                    </div>

                    <div className="create_ads_m_block create_ads_border">
                        <div className="advers___title">Описание:</div>
                        <div className="create_ads_m_flex create__ads_block">
                            <div className="input_wrap">
                                <Input
                                    className="input"
                                    type="text"
                                    placeholder={"Заголовок"}
                                    ref={inputName}></Input>
                                <p className="error">{errors.name?.message}</p>
                            </div>
                            {+type === 0 && (
                                <div className="input_wrap input_wrap_100">
                                    <Input
                                        ref={inputAbout}
                                        className="textarea"
                                        placeholder="Полное описание"></Input>
                                    <p className="error">{errors.about?.message}</p>
                                </div>
                            )}
                            {+type === 1 || +type === 2 ? (
                                <div className="input_wrap input_wrap_100">
                                    <Input ref={inputAbout} className="textarea" placeholder="Описание"></Input>
                                </div>
                            ) : (
                                ""
                            )}
                            <InputPhoto
                                images={images}
                                onLoad={setImages}
                                onDelete={setImages}
                                maxCount={5}
                                limitText={"До 5-ти фото."}
                            />
                        </div>
                    </div>

                    <div className="create__ads_b create_ads_border">
                        <div className="advers___title">Стоимость и график услуги: </div>
                        <div className="create__ads_block create__ads_b_block">
                            <div className="input_wrap inp_wrap_price">
                                <Input
                                    className="input"
                                    type="number"
                                    src={ruble}
                                    placeholder="Введите стоимость"
                                    ref={inputPriceHour}></Input>
                                <span className="span">
                                    {getCategoryFetch.price_1_name === null || getCategoryFetch.price_1_name === ""
                                        ? getCategoryFetch.parent_price_1_name
                                        : getCategoryFetch.price_1_name}
                                </span>
                            </div>

                            <div className="input_wrap inp_wrap_price">
                                <Input
                                    className="input"
                                    type="number"
                                    src={ruble}
                                    placeholder="Введите стоимость"
                                    ref={inputPriceDay}></Input>
                                <span className="span">
                                    {getCategoryFetch.price_2_name === null || getCategoryFetch.price_2_name === ""
                                        ? getCategoryFetch.parent_price_2_name
                                        : getCategoryFetch.price_2_name}
                                </span>
                            </div>

                            <div className="input_wrap">
                                <div className="open_date" onClick={modal.calendarModal}>
                                    График работы
                                    <div className="icon_date">
                                        <CalendarIcon />
                                    </div>
                                </div>
                            </div>

                            <div className="input_wrap input_wrap_toggle">
                                <CabinetSocToggle
                                    className="info_soc_advs"
                                    toggle={() => setWeekend((prevState) => !prevState)}
                                    checked={weekend}
                                />
                                <span className="span_soc_text">Выходные дни работаю</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="create_ads_border">
                    <div className="advers___title">Место оказания услуги: </div>
                    <div className="create_ads__box">
                        {/*<CitySelects getCity={getCity} />*/}
                        {elementId.map((el, i) =>
                            <CreateAdsCity getCity={getCity} key={el} arrayCityId={arrayCityId}
                                           index={i}
                                           elementId={elementId}
                                           setRerender={setRerender}
                                           setElementId={setElementId}/>
                        )
                        }

                        {ctx.userData?.account?.role === 'registrar' && <RegistrarActivity setRegistrarActivity={setRegistrarActivity}
                                            registrarActivity={registrarActivity}/> }
                    </div>
                    <p className="error">{errors.city_id?.message}</p>
                </div>

                {!device.isMobile && (
                    <div className="cabinet_ads__left">
                        <button disabled={disabled} type="button" onClick={createAds} className="btn_publish btn_save">
                            Разместить объявление
                        </button>
                    </div>
                )}
            </div>

            {device.isMobile && (
                <div className="cabinet_ads__left">
                    <button disabled={disabled} type="button" onClick={createAds} className="btn_publish btn_save">
                        Разместить объявление
                    </button>
                </div>
            )}
        </form>
    );
};

export default CreateForm;
