import { useNavigate, useParams } from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import axios from "axios";

import InputPhoto from "../../../cabinet/advertisement/InputPhoto";
import { ArrowLeftIcon } from "../../../components/icons/ArrowLeftIcon";
import { DeleteIcon } from "../../../components/icons/DeleteIcon";
import getHost, {url} from "../../../store/host-store";
import Loader from "../../../components/loader/Loader";
import { adminAction } from "../../../store/redux";
import { token } from "../../../App";
import {useChangeSeoInfoMutation, useGetSeoInfoQuery} from "../../../store/redux/queries/seo-categories";
import EditSeo from "./EditSeo";
import {Editor} from "@tinymce/tinymce-react";

const EditCategory = () => {
    let cityId = JSON.parse(localStorage.getItem("location"))?.city_id
    const [data, setData] = useState([]);
    const { data: dataInfo } = useGetSeoInfoQuery({categoryId: data.id, cityId})


    const params = useParams();
    const id = params.id;
    const history = useNavigate();
    const [changeSeoInfo] = useChangeSeoInfoMutation();

    useEffect(() => {
        setSeoInfo({seoTitle: data?.seoTitle, seoDescription: data?.seoDescription})
    }, [dataInfo])

    const editorRef = useRef(null);
    //данные из формы
    const [price1, setPrice1] = useState("");
    const [price2, setPrice2] = useState("");
    const [responsePrice, setResponsePrice] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [categorySubTitle, setCategorySubTitle] = useState("");
    const [contactsPrice, setContactsPrice] = useState("");


    //текущее и добавленое изображения
    const [currentImages, setCurrentImages] = useState(null);
    const [downloadImages, setDownloadImages] = useState([]);

    //обработка ошибок
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const [titleValid, setTitleValid] = useState(true);
    const [imageValid, setImageValid] = useState(true);

    useEffect(() => {
        setCurrentImages(data.image);
    }, [data]);

    const deleteCurrentImage = (e) => {
        if (currentImages === undefined) {
            return;
        }
        e.preventDefault();
        setCurrentImages(null);
    };
    console.log(data)
    const [seoInfo, setSeoInfo] = useState({seoTitle: data?.seoTitle, seoDescription: data?.seoDescription})
    useEffect(() => {
        window.scrollTo(0, 0);

        fetch(
            getHost({
                controller: "category",
                action: id,
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setData(result.data);
                    result.data.price_1_name === null ? setPrice1("") : setPrice1(result.data.price_1_name);
                    result.data.price_2_name === null ? setPrice2("") : setPrice2(result.data.price_2_name);
                    result.data.contact_cost === null
                        ? setContactsPrice(result.data?.costs?.contact)
                        : setContactsPrice(result.data.contact_cost);
                    result.data.order_cost === null
                        ? setResponsePrice(result.data?.costs?.order)
                        : setResponsePrice(result.data.order_cost);
                    setCategoryName(result.data.title);
                    setCategorySubTitle(result.data.subtitle)
                }
            })
            .catch((e) => console.log(e));
    }, [id]);

    const dispatch = useDispatch();

    function updateCategories() {
        fetch(
            getHost({
                controller: "category",
                action: "admin-list",
                filter: {
                    depth: 0,
                },
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    dispatch(adminAction.getCategories(result.data));
                }
            })
            .catch((e) => console.log(e));
    }

    const onSave = () => {
        const formData = new FormData();
        changeSeoInfo({categoryId: params.id, body: seoInfo})
        if (categoryName.length > 0) {
            setTitleValid(true);

            if (data.depth < 2) {
                let selectedImg =
                    downloadImages.length > 0
                        ? downloadImages[0]
                        : currentImages !== undefined && currentImages !== null
                        ? currentImages[0]
                        : null;

                if (selectedImg !== null) {
                    setImageValid(true);
                    formData.append("title", categoryName);
                    formData.append("price_1_name", price1);
                    formData.append("price_2_name", price2);
                    formData.append("image", selectedImg);
                    formData.append("order_cost", responsePrice);
                    formData.append("subtitle", categorySubTitle);
                    formData.append("description", editorRef.current.getContent());
                    formData.append("contact_cost", contactsPrice);
                    formData.append("seoDescription", seoInfo.seoDescription);
                    formData.append("seoTitle", seoInfo.seoTitle);
                    axios
                        .put(
                            getHost({
                                controller: "category",
                                action: id,
                            }),
                            formData,
                            {
                                headers: {
                                    Accept: "application/json",
                                    Authorization: token,
                                },
                            }
                        )
                        .then((res) => {
                            console.log(res.data)
                            if (res.data.success) {
                                setSuccess(true);
                                setError(false);
                                updateCategories();
                            } else {
                                setSuccess(false);
                                setError(true);
                                console.log(res.data)
                            }
                        });
                } else {
                    setImageValid(false);
                }
            } else {
                formData.append("title", categoryName);
                formData.append("price_1_name", price1);
                formData.append("price_2_name", price2);
                formData.append("order_cost", responsePrice);
                formData.append("subtitle", categorySubTitle);
                formData.append("description", editorRef.current.getContent());
                formData.append("contact_cost", contactsPrice);
                formData.append("seoDescription", seoInfo.seoDescription);
                formData.append("seoTitle", seoInfo.seoTitle);
                axios
                    .put(
                        getHost({
                            controller: "category",
                            action: id,
                        }),
                        formData,
                        {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: token,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.success) {
                            setSuccess(true);
                            setError(false);
                            updateCategories();
                        } else {
                            setSuccess(false);
                            setError(true);
                        }
                    });
            }
        } else {
            setSuccess(false);
            setTitleValid(false);
        }
    };

    const onDelete = () => {
        fetch(
            getHost({
                controller: "category",
                action: id,
            }),
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                console.log(result);
                if (result.success) {
                    updateCategories();
                    window.location.replace("/admin/category");
                } else {
                    setError(true);
                }
            });
    };

    if (Object.keys(data).length > 0) {
        return (
            <div className="admin__layout">
                <div className="admin__edit-category">
                    <div className="admin__edit-category_top">
                        <div>
                            <button
                                title="назад"
                                type="button"
                                className="admin__back-arrow"
                                onClick={() => history(-1)}>
                                <ArrowLeftIcon />
                            </button>
                            <h3>Редактировать категорию</h3>
                        </div>
                        <div>
                            {Object.keys(data).length > 0 && data.depth !== 0 && (
                                <button type="button" className="admin__btn-transparent" onClick={onDelete}>
                                    Удалить
                                </button>
                            )}
                            <button type="button" className="admin__btn-blue" onClick={onSave}>
                                Сохранить
                            </button>
                        </div>
                    </div>
                    {data.depth < 2 && currentImages !== undefined && currentImages !== null && (
                        <div className="imagesMultiple">
                            <div className="input_foto_wrap">
                                <img src={url + currentImages} alt="" />
                                <button title="удалить" type="button" onClick={(e) => deleteCurrentImage(e)}>
                                    <DeleteIcon />
                                </button>
                            </div>
                        </div>
                    )}
                    {!imageValid && <span className="admin__error">Добавьте изображение</span>}
                    {data.depth < 2 && currentImages === null && (
                        <InputPhoto
                            images={downloadImages}
                            onLoad={setDownloadImages}
                            onDelete={setDownloadImages}
                            limitText={"Вы можете добавить 1 фото."}
                        />
                    )}
                    <div className="admin__edit-category_inputs">
                        <div className="admin__edit-category_labels-wrapper mb_0">
                            <label>
                                <p>Название</p>
                            </label>
                        </div>
                        <input value={categoryName} onChange={(e) => setCategoryName(e.target.value)}></input>
                        <div className="admin__edit-category_labels-wrapper mb_0">
                            <label>
                                <p>Подпись</p>
                            </label>
                        </div>
                        <input value={categorySubTitle} onChange={(e) => setCategorySubTitle(e.target.value)}></input>
                        <div className="admin__edit-category_labels-wrapper">
                            <label>
                                <p>Цена первая</p>
                                <input value={price1} onChange={(e) => setPrice1(e.target.value)}></input>
                            </label>
                            <label>
                                <p>Цена вторая</p>
                                <input value={price2} onChange={(e) => setPrice2(e.target.value)}></input>
                            </label>
                        </div>
                        <div className="admin__edit-category_labels-wrapper">
                            <label>
                                <p>Сумма отклика</p>
                                <input value={responsePrice} onChange={(e) => setResponsePrice(e.target.value)}></input>
                            </label>
                            <label>
                                <p>Сумма открытия контактов</p>
                                <input value={contactsPrice} onChange={(e) => setContactsPrice(e.target.value)}></input>
                            </label>
                        </div>
                        <EditSeo data={data} categoryId={data.id} setSeoInfo={setSeoInfo} seoInfo={seoInfo}/>

                        <div>
                            <div className="admin__edit-category_labels-wrapper">
                                <label>
                                    <p>Описание</p>
                                </label>
                            </div>
                            <Editor
                                onInit={(evt, editor) => (editorRef.current = editor)}
                                initialValue={data.description}
                                init={{
                                    height: 350,
                                    menubar: false,
                                    plugins:
                                        "advlist autolink lists link image charmap print preview anchor" +
                                        "searchreplace visualblocks code fullscreen" +
                                        "insertdatetime media table paste code help wordcount",
                                    toolbar:
                                        "undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ",
                                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                }}
                            />
                        </div>
                    </div>
                    {!titleValid && <span className="admin__error">Поле с названием обязательно для заполнения</span>}
                    {error && <span className="admin__error">Что-то пошло не так, попробуйте позже</span>}
                    {success && <span className="admin__success">Изменения успешно сохранены</span>}
                </div>
            </div>
        );
    } else {
        return (
            <div className="admin__layout">
                <Loader />
            </div>
        );
    }
};

export default EditCategory;
