import {NavLink} from "react-router-dom";
import s from "./style.module.scss"

export const CategoriesHistoryBtns = () => {
  return(
      <div className={s.flex}>
        <NavLink to={`historyAdv`} className={s.btn}>История созданных объявлений</NavLink>
        <NavLink to={`history`} className={s.btn}>История изменений</NavLink>
      </div>
  )
}