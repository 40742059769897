import { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import CreateCategory from "./main/categories/CreateCategory";
import ArticleCreate from "./main/blog/create/ArticleCreate";
import PartnersCreate from "./main/partners/PartnersCreate";
import UserStatistic from "./main/statistic/UserStatistic";
import EditCategory from "./main/categories/EditCategory";
import FundCreate from "./main/charity/create/FundCreate";
import ArticleEdit from "./main/blog/create/ArticleEdit";
import FaqEditQuestion from "./main/faq/FaqEditQuestion";
import PartnersEdit from "./main/partners/PartnersEdit";
import EditPromo from "./main/promotion/edit/EditPromo";
import FaqNewQuestion from "./main/faq/FaqNewQuestion";
import Categories from "./main/categories/Categories";
import FundEdit from "./main/charity/create/FundEdit";
import DeviceContext from "../store/device-context";
import Statistic from "./main/statistic/Statistic";
import StopWords from "./main/stopWords/StopWords";
import Promotion from "./main/promotion/Promotion";
import OneObject from "./main/objects/OneObject";
import CreateRole from "./main/roles/CreateRole";
import Partners from "./main/partners/Partners";
import AdminOneUser from "./main/users/OneUser";
import Funds from "./main/charity/main/Funds";
import Objects from "./main/objects/Objects";
import Support from "./main/support/Support";
import EditRole from "./main/roles/EditRole";
import AdminAside from "./aside/AdminAside";
import AdminInfo from "./main/AdminInfo";
import Blog from "./main/blog/main/Blog";
import Users from "./main/users/Users";
import Roles from "./main/roles/Roles";
import NotFound from "../components/404/NotFound";
import Faq from "./main/faq/Faq";
import Promocode from "./main/promocode/Promocode";
import EditAdminAds from "./main/promotion/edit/EditAdminAds";
import UserModeratedStatistic from "./main/statistic/UserModeratedStatistic";
import StatisticRegistrator from "./main/statistic/registrator/StatisticRegistrator";
import {RegistratorStatisticInner} from "./main/statistic/registrator/RegistratorStatisticInner";
import Cities from "./main/cities/Cities";
import {PartnerProgramMain} from "./main/partnerProgram/PartnerProgramMain";
import {PartnerProgramWallet} from "./main/partnerProgram/partnerProgramWallet/PartnerProgramWallet";
import {History} from "./main/categories/history/History";
import {HistoryAdv} from "./main/categories/history/HistoryAdv";

const Admin = () => {
    const device = useContext(DeviceContext);

    return (
        <section className="container">
            <div className="cabinet__box">
                {device.openAside && <AdminAside />}
                <div className="admin">
                    {device.isMobile && (
                        <button type="button" className="open-admin-menu" onClick={device.toggleAside}>
                            <div className="line">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            Меню
                        </button>
                    )}
                    <PartnerProgramWallet />
                    <Routes>
                        <Route path="/" element={<AdminInfo />}></Route>


                            <>
                                <Route path="/objects" element={<Objects />}></Route>
                                <Route path="/objects/:id" element={<OneObject />}></Route>

                                <Route path="/users" element={<Users />}></Route>
                                <Route path="/users/:id" element={<AdminOneUser />}></Route>

                                <Route path="/category" element={<Categories />}></Route>
                                <Route path="category/edit/:id" element={<EditCategory />}></Route>
                                <Route path="category/add/:id" element={<CreateCategory />}></Route>

                                <Route path="category/history" element={<History />}></Route>
                                <Route path="category/historyAdv" element={<HistoryAdv />}></Route>

                                <Route path="/roles" element={<Roles />}></Route>
                                <Route path="roles/edit/:id" element={<EditRole />}></Route>
                                <Route path="roles/create" element={<CreateRole />}></Route>

                                <Route path="statistic" element={<Statistic />}></Route>
                                <Route path="statistic/:id" element={<UserStatistic />}></Route>
                                <Route path="statisticRegistrator" element={<StatisticRegistrator />}></Route>
                                <Route path="statisticRegistrator/:id" element={<RegistratorStatisticInner />}></Route>
                                <Route path="ad_statistic" element={<Statistic />}></Route>
                                <Route path="ad_statistic/:id" element={<UserModeratedStatistic />}></Route>
                            </>

                        <Route path="/support" element={<Support />}></Route>
                        <Route path="blog" element={<Blog />}></Route>
                        <Route path="blog/edit/:id" element={<ArticleEdit />}></Route>
                        <Route path="blog/create" element={<ArticleCreate />}></Route>
                        <Route path="charity" element={<Funds />}></Route>
                        <Route path="charity/create" element={<FundCreate />}></Route>
                        <Route path="charity/edit/:id" element={<FundEdit />}></Route>

                        <Route path="faq" element={<Faq />}></Route>
                        <Route path="faq/create" element={<FaqNewQuestion />}></Route>
                        <Route path="faq/edit/:id" element={<FaqEditQuestion />}></Route>

                        <Route path="partners" element={<Partners />}></Route>
                        <Route path="partners/create" element={<PartnersCreate />}></Route>
                        <Route path="partners/edit/:id" element={<PartnersEdit />}></Route>

                        <Route path="promotion" element={<Promotion />}></Route>
                        <Route path="promotion/edit/:type" element={<EditPromo />}></Route>
                        <Route path="ads/edit" element={<EditAdminAds />}></Route>

                        <Route path="stop-words" element={<StopWords />}></Route>
                        <Route path="promocode" element={<Promocode />}></Route>
                        <Route path="cities" element={<Cities />}></Route>
                        <Route path="partner_program" element={<PartnerProgramMain />}></Route>
                        <Route path="*" element={<NotFound />}></Route>
                    </Routes>
                </div>
            </div>
        </section>
    );
};

export default Admin;
