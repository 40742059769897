import {useContext, useEffect, useState} from "react";
import {link} from "../../store/host-store";
import {token} from "../../App";
import {useForm} from "react-hook-form";
import AuthContext from "../../store/auth-context";
import {MtsButton} from "../../cabinet/cabinetInfo/MtsButton";
import {AdvertiserAbbreviation} from "./AdvertiserAbbreviation";

const AdvertiserForm = ({organization, legalForm}) => {
    const [data, setData] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [errors1, setErrors] = useState('');
    const [formChanged, setFormChanged] = useState(false);
    const [abbreviation, setAbbreviation] = useState('');

    const ctx = useContext(AuthContext);


    const {register, formState: {errors}, handleSubmit} = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        values: {
            name: data[0]?.type  ? data[0]?.name : '',
            inn: data[0]?.type ? data[0]?.inn.trim() : '',
            ogrn: data[0]?.type ? data[0]?.ogrn : '',
            address: data[0]?.type ? data[0]?.address : '',
            okved: data[0]?.type ? data[0]?.okved : '',
        }
    });

    const changeForm = () => {
        setFormChanged(true);
    }

    useEffect(() => {
        fetch(`${link}/organization?filter[user_id]=${ctx.isUserId}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            }
        })
            .then(res => res.json())
            .then(res => {
                setData(res.data)
                setAbbreviation({
                    value: 1,
                    label: res.data[0].legal_form
                })
            })
    }, [organization, rerender, ctx.isUserId])

    const onSubmit = (d) => {
        if(organization !== 3){
            d.type = organization;
        }
        if(organization === 3){
            d.legal_form = abbreviation.label
            d.type = 2
            if(abbreviation.length === 0){
                return setErrors('Выберите аббревиатуру')
            }
        }
        if (data.length === 0) {
            fetch(`${link}/organization`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(d)
            }).then(res => res.json())
                .then(res => console.log(res.data))
                .then(() => {
                    setRerender(prevState => !prevState)
                    window.location.reload()
                })
        } else {
            if (data[0]?.type !== organization) {
                setErrors('У вас уже есть организация');
            }

            if(formChanged){
                fetch(`${link}/organization/${data[0].id}`, {
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    body: JSON.stringify(d)
                }).then(res => res.json())
                    .then(res => console.log(res.data))
                    .then(() => {
                        setRerender(prevState => !prevState)
                        window.location.reload()
                    })
            }else{
                alert("Необходимо проверить правильность введенных данных и внести изменения для повторной проверки")
            }

            setErrors('')
        }
    }
    const disabledForm = ctx.userData.organization?.status === 1 ||
        ctx.userData.organization?.on_moderation || ctx.userData.organization?.status === 0


    return (
        <>
        {organization !== 0 ?
            <fieldset disabled={disabledForm}>
                <form onSubmit={handleSubmit(onSubmit)} onChange={changeForm}>
                    <div>
                        {(organization === 3 || legalForm) &&
                            <>
                                <AdvertiserAbbreviation setAbbreviation={setAbbreviation} abbreviation={abbreviation}/>
                                <p className="error">{errors1}</p>
                            </>
                        }
                        <div className="flex_inn_ogrn">
                            <div className="block_org">
                                <div
                                    className="adv_cabinet_title">{organization === 0 ? 'ФИО:' : organization === 1 ? 'ФИО:' : 'Название компании:'}</div>
                                <div className="input" style={{marginBottom: '12px'}}>
                                    <input type="text"
                                           placeholder={organization === 1 ? 'Например, Иванов Пётр Иванович' : 'Введите название'}
                                           {...register('name', {
                                               validate: value => !!value.trim(),
                                               required: 'Поле не должно быть пустым',
                                               pattern: {
                                                   value: organization === 1 ? /^([^0-9]*)$/ : '',
                                                   message: 'Должен содержать как минимум один пробел и не содержать цифр'
                                               }
                                           })}/>
                                    {/*<input key={organization} type="text" defaultValue={ld} onChange={() => setLD(organization)} {...register('type', {value: organization})}/>*/}
                                    {errors?.name ? <p className='error'>{errors?.name.message}</p> : ''}
                                    {errors?.name?.type === 'validate' ?
                                        <p className='error'>Поле не должно быть пустым</p> : ''}
                                </div>
                            </div>

                            <div className="block_org">
                                <div
                                    className="adv_cabinet_title">Основной ОКВЭД:
                                </div>
                                <div className="input" style={{marginBottom: '12px'}}>
                                    <input type="text" pattern="[0-9.]+"
                                           placeholder='Введите ОКВЭД' {...register('okved')}/>
                                    {errors?.okved ? <p className='error'>{errors?.okved.message}</p> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="flex_inn_ogrn">
                            <div className="block_org">
                                <div className="adv_cabinet_title">ИНН:</div>
                                <div className="input">
                                    <input type="number" placeholder="Введите ИНН"
                                           {...register('inn', {
                                               required: 'Поле не должно быть пустым',
                                               minLength: organization === 1 ? {
                                                   value: 12,
                                                   message: 'Минимум 12 цифр'
                                               } : {value: 10, message: 'Минимум 10 цифр'},
                                               maxLength: organization === 1 ? {
                                                   value: 12,
                                                   message: 'Максимум 12 цифр'
                                               } : {value: 10, message: 'Максимум 10 цифр'},
                                           })}
                                    />
                                    {errors?.inn ? <p className='error'>{errors?.inn.message}</p> : ''}
                                </div>
                            </div>
                            {organization !== 0 &&
                                <div className="block_org">
                                    <div className="adv_cabinet_title">{organization === 1 ? 'ОГРНИП:' : 'ОГРН:'}</div>
                                    <div className="input">
                                        <input type="number" placeholder="Введите ОГРН" {...register('ogrn', {
                                            required: 'Поле не должно быть пустым',
                                            minLength: organization === 1 ? {
                                                value: 15,
                                                message: 'Минимум 15 цифр'
                                            } : {value: 13, message: 'Минимум 13 цифр'},
                                            maxLength: organization === 1 ? {
                                                value: 15,
                                                message: 'Максимум 15 цифр'
                                            } : {value: 13, message: 'Максимум 13 цифр'},
                                        })}/>
                                        {errors?.ogrn ? <p className='error'>{errors?.ogrn.message}</p> : ''}
                                    </div>
                                </div>
                            }
                        </div>
                        <div
                            className="adv_cabinet_title">{organization === 2 ? 'Юридический адрес:' : organization === 3 ? 'Юридический адрес' : 'Адрес места жительства:'}</div>
                        <div className="input">
                            <input type="text"
                                   placeholder="Введите адрес в формате: индекс, регион, город, улица, дом" {...register('address')}/>
                        </div>
                        <p className='error'>{errors1.message}</p>
                        <p style={{fontSize: "14px", marginTop: "10px"}}><span style={{color: "red"}}>⃰</span> -
                            требование Федерального закона "О рекламе" от 13.03.2006 №38-ФЗ</p>
                        {!disabledForm &&
                            <button type='submit' style={{marginTop: "14px"}} className='btn_custom'>Сохранить</button>}
                    </div>
                </form>
            </fieldset>
            : <MtsButton/>
}
</>
    )
}

export default AdvertiserForm;