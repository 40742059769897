import {useContext, useEffect, useState} from "react";
import uploadedFileLink from "./images/accDocs.svg";
import {useSelector} from "react-redux";
import {usePutFileMutation} from "../../../store/redux/queries/partnerProgram/partner-program-query";
import ModalsContext from "../../../store/modals-context";
import Loader from "../../../components/loader/Loader";
import {useGetDocumentQuery} from "../../../store/redux/queries/mts/mts-queries";

export const AccountAgreementPin = () => {
    const ctx = useContext(ModalsContext)
    const agreementId = useSelector(state => state.referralSlice.agreementID)
    const [file, setFile] = useState();
    const [setPutFile, {data}, isLoading] = usePutFileMutation()
    const [fileName, setFileName] = useState('');
    const {data: getDocument} = useGetDocumentQuery('');

    const handleFileChange = (event) => {
        const fileMain = event.target.files[0];
        setFile(fileMain)
        setFileName(fileMain ? fileMain.name : '');
    };

    const sendFile = async () => {
        if(!file){
            return alert("Прикрепите файл")
        }
        await setPutFile({id: agreementId, file: file})
        await ctx.partnerProgramModal()
    }


  return(
              <>
              <div className="account_agreement_flex">
                  <div className="account_agreement_left">
                      <span className="account_agreement_span">01</span>
                      <p className="acc_agr_text_flex">Скачайте договор и подпишите его</p>
                      <a href={getDocument?.data.url} style={{cursor: 'pointer'}} target="_blank" rel="noopener noreferrer" download>
                          <a type="button" className="agreement_btn" download href={getDocument?.data.url}>Скачать договор</a>
                      </a>
                  </div>
                  <div className="account_agreement_right">
                      <span className="account_agreement_span">02</span>
                      <p className="acc_agr_text_flex">Прикрепите и отправьте договор</p>
                      <label className="input-file">
                          <input type="file" name="file" onChange={handleFileChange}/>
                          <span>{fileName || 'Прикрепить'}</span>
                      </label>
                  </div>
              </div>

                  {isLoading ? <Loader /> : <button type="button" className="acc_btn_blue" onClick={sendFile}>Отправить</button>}
              </>
  )
}