import {link} from "../../store/host-store";
import {token} from "../../App";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../store/auth-context";

const AdvertiserOrganization = ({title0, title1, title2, title3, setOrganization, organization, setLegalForm}) => {
    const customOrganization = window.location.href.indexOf('advertiser') > - 1
    const auth = useContext(AuthContext)
    const [disable, setDisable] = useState(false);
    const [legal_form, setLegal_form] = useState(false);

    useEffect(() => {
        if(customOrganization){
            if(auth.isUserId !== 0) {
                fetch(`${link}/organization?filter[user_id]=${auth.isUserId}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                    }
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.data.length > 0 && res.data[0].status !== 2) {
                            setOrganization(res.data[0]?.type)
                            setDisable(true);
                            if(res.data[0].legal_form !== null || res.data[0].legal_form.length > 0 ){
                                setLegalForm(true)
                            }
                            setLegal_form(res.data[0].legal_form)
                        }
                    })
            }
        }
    }, [auth.isUserId])


  return(
      <div className="flex_organization">
          {(!disable || (disable && organization === 0) )&& <div onClick={() => setOrganization(0)}
              className={`organization ${organization === 0 ? 'active' : ''}`}>{title0}</div>}
          {(!disable || (disable && organization === 1) )&& <div onClick={() => setOrganization(1)}
                className={`organization ${organization === 1 ? 'active' : ''}`}>{title1}</div>}
          {(!disable || (disable && organization === 2 && legal_form.length === 0)) && <div onClick={() => setOrganization(2)}
                className={`organization ${organization === 2 || legal_form.length === 0 ? 'active' : ''}`}>{title2}</div>}
          {(!disable || (disable && organization === 3 || legal_form?.length > 0)) && <div onClick={() => setOrganization(3)}
                 className={`organization ${organization === 3 || legal_form?.length > 0 ? 'active' : ''}`}>{title3}</div>}
      </div>
  )
}

export default AdvertiserOrganization;