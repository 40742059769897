import "./Modal.sass";
import { useContext, useState } from "react";
import "react-nice-dates/build/style.css";
import AuthContext from "../../store/auth-context";
import { token } from "../../App";
import getHost from "../../store/host-store";
import ModalCloseSvg from "./ModalSvg/ModalCloseSvg";
import ModalNonConfirm from "./ModalNonConfirm";
import ModalUnauth from "./Authentification/LoginModal";
import ModalsContext from "../../store/modals-context";

const ModalRequestOffer = ({ id }) => {
    const ctx = useContext(AuthContext);
    const modal = useContext(ModalsContext);
    const [about, setAbout] = useState("");
    const [errors, setErrors] = useState({});

    const handleClick = (event) => {
        event.preventDefault();

        let data = {
            about: about,
            object_id: id,
            user_id: ctx.isUserId,
        };

        fetch(
            getHost({
                controller: "order",
            }),
            {
                method: "POST",
                headers: {
                    Accept: "application/json,",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 201 || result.status === 200) {
                    modal.isOrderedModal();
                } else {
                    showErrors(result.data);
                }
            });
    };

    function showErrors(errorList) {
        setErrors("");
        errorList.map((error) => {
            switch (error.field) {
                case "user_id":
                    setErrors((prevState) => ({ ...prevState, user_id: error }));
                    break;
                case "address":
                    setErrors((prevState) => ({ ...prevState, address: error }));
                    break;
                case "about":
                    setErrors((prevState) => ({ ...prevState, about: error }));
                    break;
                default:
            }
        });
    }
    return (
        <>
            {ctx.userData?.id ? (
                ctx.userData.mail_confirmed === null || ctx.userData.mail_confirmed === 0 ? (
                    <ModalNonConfirm />
                ) : (
                    <form className="modal modal__card3" onSubmit={handleClick}>
                        <div className="closeModal" onClick={() => modal.closeModal()}>
                            <ModalCloseSvg />
                        </div>
                        <h3 className="title">Подать заявку</h3>
                        <div className="request_sub_text">Заполните информацию о предложении ниже:</div>
                        <div className="input">
                            <textarea
                                required
                                placeholder="Опишите предложение"
                                onChange={(e) => setAbout(e.currentTarget.value)}
                                value={about}></textarea>
                            <p className="error">{errors.about?.message}</p>
                        </div>

                        <button className="modal__btn" type="submit">
                            Отправить
                        </button>
                    </form>
                )
            ) : (
                <ModalUnauth />
            )}
        </>
    );
};

export default ModalRequestOffer;
