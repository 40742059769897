import { useDispatch, useSelector } from "react-redux";
import { adminAction } from "../../../store/redux";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";

import noPhoto from "../../../assets/images/stubs/object-mob.png";
import { EditIcon } from "../../../components/icons/EditIcon";
import { AddIcon } from "../../../components/icons/AddIcon";
import getHost, { url } from "../../../store/host-store";
import Loader from "../../../components/loader/Loader";
import { token } from "../../../App";
import {CategoriesHistoryBtns} from "./CategoriesHistoryBtns";

let theme = createTheme();
theme = createTheme(theme, {
    components: {
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    padding: "0",
                },
                content: {
                    [theme.breakpoints.down("md")]: {
                        margin: "10px 0",
                    },
                    margin: "0",
                    "&.Mui-expanded": {
                        margin: "0",
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    "::before": {
                        display: "none",
                    },
                    boxShadow: "none",
                    "&.Mui-expanded": {
                        margin: "0",
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: "0",
                },
            },
        },
    },
});

const Categories = () => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.adminCategories.categories);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (categories.length > 0) {
            setTimeout(() => {
                setLoading(false);
            }, 100);
        } else {
            fetch(
                getHost({
                    controller: "category",
                    action: "admin-list",
                    filter: {
                        depth: 0,
                    },
                }),
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                    },
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    setLoading(false);
                    console.log(result.data)
                    if (result.success) {
                        dispatch(adminAction.getCategories(result.data));
                    }
                })
                .catch((e) => console.log(e));
        }
    }, [categories.length, dispatch]);

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return loading ? (
        <div className="admin__layout">
            <Loader />
        </div>
    ) : (
        <div className="admin__layout">
            <CategoriesHistoryBtns />
            <h3>Категории</h3>
            <div className="admin__list-titles admin__list-titles_categories">
                {/*<p>Фото</p>*/}
                <p>Название</p>
                <p>Объявлений</p>
                <p>Подкатегорий</p>
                <p>Title</p>
                <p>Description</p>
                <p>Описание раздела</p>
            </div>
            <ThemeProvider theme={theme}>
                {categories.map((el) => (
                    <Accordion key={el.id} expanded={expanded === el.id} onChange={handleChange(el.id)}>
                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                            <div className="admin__content-card admin__content-card_categories" key={el.id}>
                                {/*{el.image === null ? (*/}
                                {/*    <img src={noPhoto} alt="category" />*/}
                                {/*) : el.webpFilename ? (*/}
                                {/*    <img src={url + el.webpFilename} alt="category" />*/}
                                {/*) : (*/}
                                {/*    <img src={url + el.image} alt="category" />*/}
                                {/*)}*/}
                                <p>{el.title}</p>
                                <p>{el.objectCount}</p>
                                <p>{el.amountOfChildren}</p>
                                <p>{el.seoTitle.length > 0 ? <span className="s_green">Есть</span> : <span className="s_red">Нет</span>}</p>
                                <p>{el.seoDescription.length > 0 ? <span className="s_green">Есть</span> : <span className="s_red">Нет</span>}</p>
                                <p>{el.description.length > 0 ? <span className="s_green">Есть</span> : <span className="s_red">Нет</span>}</p>
                                <div className="admin__content-card_btns">
                                    <NavLink
                                        to={{
                                            pathname: `add/${el.id}`,
                                        }}
                                        state={{ parent_id: el.id, type: el.type, depth: el.depth }}>
                                        <AddIcon />
                                    </NavLink>
                                    <NavLink to={`edit/${el.id}`}>
                                        <EditIcon />
                                    </NavLink>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {el.children.map((subcategory) => {
                                return subcategory.type !== 4 ? (
                                    <Accordion key={subcategory.id} className="admin__content-card_subwrapper">
                                        <AccordionSummary>
                                            <div className="admin__content-card admin__content-card_subcategories">
                                                {/*{subcategory.image === null ? (*/}
                                                {/*    <img src={noPhoto} alt="category" />*/}
                                                {/*) : subcategory.webpFilename ? (*/}
                                                {/*    <img src={url + subcategory.webpFilename} alt="category" />*/}
                                                {/*) : (*/}
                                                {/*    <img src={url + subcategory.image} alt="category" />*/}
                                                {/*)}*/}
                                                <p>{subcategory.title}</p>
                                                <p>{subcategory.objectCount}</p>
                                                <p>{subcategory.amountOfChildren}</p>
                                                <p>{subcategory.seoTitle?.length > 0 ? <span className="s_green">Есть</span> : <span className="s_red">Нет</span>}</p>
                                                <p>{subcategory.seoDescription?.length > 0 ? <span className="s_green">Есть</span> : <span className="s_red">Нет</span>}</p>
                                                <p>{subcategory.description?.length > 0 ? <span className="s_green">Есть</span> : <span className="s_red">Нет</span>}</p>
                                                <div className="admin__content-card_btns">
                                                    <NavLink
                                                        to={{
                                                            pathname: `add/${subcategory.id}`,
                                                        }}
                                                        state={{
                                                            type: subcategory.type,
                                                            parent_id: subcategory.id,
                                                            depth: subcategory.depth,
                                                        }}>
                                                        <AddIcon/>
                                                    </NavLink>
                                                    <NavLink to={`edit/${subcategory.id}`}>
                                                        <EditIcon/>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="admin__content-card_childwrapper">
                                                {subcategory.children.map((children) => {
                                                    return (
                                                        <div
                                                            key={children.id}
                                                            className="admin__content-card admin__content-card_childrens">
                                                            <p>{children.title}</p>
                                                            <p>{children.objectCount}</p>
                                                            <p>{children.seoTitle?.length > 0 ? <span className="s_green">Есть</span> : <span className="s_red">Нет</span>}</p>
                                                            <p>{children.seoDescription?.length > 0 ? <span className="s_green">Есть</span> : <span className="s_red">Нет</span>}</p>
                                                            <p>{children.description?.length > 0 ? <span className="s_green">Есть</span> : <span className="s_red">Нет</span>}</p>
                                                            <div className="admin__content-card_btns">
                                                                <NavLink to={`edit/${children.id}`}>
                                                                    <EditIcon/>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ) : (
                                    ''
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </ThemeProvider>
        </div>
    );
};

export default Categories;
