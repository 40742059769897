import s from "../style.module.scss"
import {NavLink, useNavigate} from "react-router-dom";
import {advData} from "./mockData";
import {EyeIcon} from "../../../../components/icons/EyeIcon";
import {useGetHistoryAdvQuery, useGetSeoHistoryQuery} from "../../../../store/redux/queries/seo-categories";

export const HistoryAdv = () => {
    const history = useNavigate();
    const {data} = useGetHistoryAdvQuery('');
    console.log(data)
  return(
      <div className="admin__layout">
          <div className={s.flex_title}>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" onClick={() => history(-1)}>
                  <path fill="#858585" d="M11.25 5.25H2.355l2.722-3.27a.751.751 0 0 0-1.155-.96l-3.75 4.5a.893.893 0 0 0-.067.112c0 .038 0 .06-.052.098A.75.75 0 0 0 0 6a.75.75 0 0 0 .053.27c0 .037 0 .06.052.097.02.04.042.077.067.113l3.75 4.5a.751.751 0 0 0 1.155-.96L2.355 6.75h8.895a.75.75 0 1 0 0-1.5Z"/>
              </svg>
              <h3>История создания объявлений</h3>
          </div>

          <div className={s.title_history}>
              <p>Когда</p>
              <p>Категория</p>
              <p>Месторасположение</p>
              <p>Название объявления</p>
          </div>

          {data?.data.map(item => (
              <div key={item.id} className={s.item_history_adv}>
                  <p>{item.created_at}</p>
                  <div>
                      {item.categories.map((item) => (
                          <p key={item.title}>{item.title}</p>
                      ))}
                  </div>
                  <p>{item.city}</p>
                  <p style={{paddingLeft: "12px"}}>{item.name}</p>
                  <NavLink className={s.icon} to={`/admin/objects/${item.object_id}`}>
                      <EyeIcon />
                  </NavLink>
              </div>
          ))}
      </div>
  )
}