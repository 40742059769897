import { url } from "../../../../store/host-store";
import RequestOrdersSvg from "../../../request/svg/RequestOrdersSvg";
import style from "../../style.module.sass";
import object from "../../../../assets/images/stubs/object-mob.png";
import moment from "moment";
import uuid from "react-uuid";

const SeekObject = ({ item, setMobileVisibleCart, device, objectId, setObjectId, key }) => {
    const leftTime = moment(item.final_date).endOf("day").fromNow().replace("через", "Осталось: ");

    return (
        <div key={uuid()}
            onClick={() => {
                setObjectId(item.id);
                if (device.isMobile) {
                    setMobileVisibleCart(true);
                }
            }}
            className={`${style.cart} ${objectId === item.id && style.active} ${
                item.amountOfConfirmedOrders < 1 ? style.empty : ""
            }`}>
            {item.image === null ? (
                <img className={style.obj_img} src={object} alt="object" />
            ) : (
                <img className={style.obj_img} src={url + item.image.filename} alt="object" />
            )}

            <div className={style.text}>
                <h3>{item.name}</h3>
                <div className={style.location_wrapper}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="9" fill="none">
                        <path
                            fill="#B6BFE7"
                            d="M3.5 0a3.455 3.455 0 0 0-2.474 1.056A3.657 3.657 0 0 0 0 3.6C0 5.065 1.034 6.281 2.129 7.57c.346.407.705.83 1.025 1.256A.44.44 0 0 0 3.5 9a.428.428 0 0 0 .346-.175c.32-.427.679-.849 1.025-1.256C5.966 6.28 7 5.065 7 3.6a3.657 3.657 0 0 0-1.026-2.544A3.455 3.455 0 0 0 3.5 0Zm0 4.95c-.26 0-.513-.08-.73-.227a1.343 1.343 0 0 1-.483-.606 1.386 1.386 0 0 1 .285-1.472c.183-.188.417-.317.672-.369.255-.052.518-.025.758.077.24.102.445.275.59.497a1.378 1.378 0 0 1-.164 1.705c-.246.253-.58.395-.928.395Z"
                        />
                    </svg>
                    {item.city.name}
                </div>
                <div className={style.time_left}>
                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.5 0C3.60999 0 2.73996 0.26392 1.99994 0.758387C1.25991 1.25285 0.683138 1.95566 0.342544 2.77792C0.00194978 3.60019 -0.0871652 4.50499 0.0864683 5.3779C0.260102 6.25082 0.688685 7.05264 1.31802 7.68198C1.94736 8.31131 2.74918 8.7399 3.6221 8.91353C4.49501 9.08716 5.39981 8.99805 6.22208 8.65746C7.04434 8.31686 7.74715 7.74009 8.24161 7.00006C8.73608 6.26004 9 5.39001 9 4.5C9 3.90905 8.88361 3.32389 8.65746 2.77792C8.43131 2.23196 8.09984 1.73588 7.68198 1.31802C7.26412 0.900156 6.76804 0.568688 6.22208 0.342542C5.67611 0.116396 5.09095 0 4.5 0ZM6.3 4.95H4.5C4.38065 4.95 4.26619 4.90259 4.1818 4.8182C4.09741 4.73381 4.05 4.61935 4.05 4.5V1.8C4.05 1.68065 4.09741 1.56619 4.1818 1.4818C4.26619 1.39741 4.38065 1.35 4.5 1.35C4.61935 1.35 4.73381 1.39741 4.8182 1.4818C4.90259 1.56619 4.95 1.68065 4.95 1.8V4.05H6.3C6.41935 4.05 6.53381 4.09741 6.6182 4.1818C6.70259 4.26619 6.75 4.38065 6.75 4.5C6.75 4.61935 6.70259 4.73381 6.6182 4.8182C6.53381 4.90259 6.41935 4.95 6.3 4.95Z"
                            fill="#B6BFE7"
                        />
                    </svg>
                    {leftTime}
                </div>
            </div>

            <div className={style.counter}>
                <RequestOrdersSvg />
                <div className={style.count}>{item.amountOfConfirmedOrders}</div>
            </div>
        </div>
    );
};

export default SeekObject;
