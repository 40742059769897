import { NavLink } from "react-router-dom";

import { RatingIcon } from "../../../components/icons/RatingIcon";
import { RatingZero } from "../../../components/icons/RatingZero";
import noAvatar from "../../../assets/images/stubs/avatar.svg";
import { EyeIcon } from "../../../components/icons/EyeIcon";
import { url } from "../../../store/host-store";
import uuid from "react-uuid";
import moment from "moment";
import UserOrganizationInfo from "./UserOrganizationInfo";
import UserAdsInfo from "./UserAdsInfo";

const UserModeratedList = ({ dataUsers, model }) => {
    return (
        <>
            <div className="admin__list-titles admin__list-titles_users">
                <p>ID</p>
                <p>Фото</p>
                <p>Имя</p>
                <p>Изменено</p>
                <p>Статус</p>
            </div>
            <div>
                {dataUsers.map((el) =>
                    el !== null ?
                        <div className="admin__content-card_wrapper" key={el.id}>
                            <div className="admin__content-card admin__content-card_users"
                                 style={{background: "none", boxShadow: "none", marginBottom: 0}}
                                 key={uuid()}>
                                <span>#{el?.object?.user?.id}</span>
                                {el?.object?.user?.avatar === null ? (
                                    <img src={noAvatar} alt="avatar" />
                                ) : el?.webpFilename ? (
                                    <img src={url + el?.object?.user?.webpFilename} alt="avatar" />
                                ) : (
                                    <img src={url + el?.object?.user?.avatar} alt="avatar" />
                                )}

                                <p>{el?.object?.user?.name}</p>
                                <p>
                                    {moment(el?.object?.updated).format('DD.MM.YYYY HH:mm')}
                                </p>

                                {el.status === 0 ? (
                                    <p className="admin__waiting">Ожидает</p>
                                ) : el.status === 1 ? (
                                    <p className="admin__done">Подтверждено</p>
                                ) : el.status === 2 ? (
                                    <p className="admin__rejected">Отклонено</p>
                                ) : el.status === 3 ? (
                                    <p className="admin__deactive">Деактивировано пользователем</p>
                                ) : (
                                    <p className="admin__delete">Удалено пользователем</p>
                                )}

                                <div className="admin__content-card-icons">
                                    {el?.object ?
                                        <NavLink to={`/admin/users/${el?.object?.user_id}`}>
                                            <EyeIcon />
                                        </NavLink>
                                        :
                                        <NavLink to={`/404`}>
                                            <EyeIcon />
                                        </NavLink>
                                    }
                                </div>
                            </div>
                            {model === "organization" && <UserOrganizationInfo data={el?.object}/>}
                            {model === "advertisement" && <UserAdsInfo data={el?.object}/>}
                        </div>
                        :
                        <div className="admin__content-card admin__content-card_users" key={uuid()} style={{display: 'flex'}}>
                        Пользователь удален
                        </div>
                )}
            </div>
        </>
    );
};

export default UserModeratedList;
