import RequestOrdersSvg from "../../../request/svg/RequestOrdersSvg";
import object from "../../../../assets/images/stubs/object-mob.png";
import { url } from "../../../../store/host-store";
import style from "../../style.module.sass";
import uuid from "react-uuid";

const OfferObject = ({ item, objectId, setObjectId, device, setMobileVisibleCart, key }) => {
    return (
        <div key={uuid()}
            onClick={() => {
                setObjectId(item.id);
                if (device.isMobile) {
                    setMobileVisibleCart(true);
                }
            }}
            className={`${style.cart} ${objectId === item.id && style.active} ${
                item.amountOfConfirmedOrders < 1 ? style.empty : ""
            }`}>
            {item.image === null ? (
                <img className={style.obj_img} src={object} alt="object" />
            ) : (
                <img className={style.obj_img} src={url + item.image.filename} alt="object" />
            )}

            <div className={style.text}>
                <h3>{item.name}</h3>
                <div className={style.prices}>
                    <div>
                        <h5>{item.price_1} ₽</h5>
                        <span>{item.price_1_name}</span>
                    </div>
                    <div>
                        <h5>{item.price_2} ₽</h5>
                        <span>{item.price_2_name}</span>
                    </div>
                </div>
            </div>

            <div className={style.counter}>
                <RequestOrdersSvg />
                <div className={style.count}>{item.amountOfConfirmedOrders}</div>
            </div>
        </div>
    );
};

export default OfferObject;
