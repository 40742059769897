import s from "../style.module.scss"
import {useNavigate} from "react-router-dom";
import {mockData} from "./mockData";
import {ArrowRight} from "./ArrowRight";
import uuid from "react-uuid";
import {useGetSeoHistoryQuery} from "../../../../store/redux/queries/seo-categories";

export const History = () => {
    const history = useNavigate();
    const {data} = useGetSeoHistoryQuery('');

    console.log(data)
    return(
        <div className="admin__layout">
            <div className={s.flex_title}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" onClick={() => history(-1)}>
                    <path fill="#858585" d="M11.25 5.25H2.355l2.722-3.27a.751.751 0 0 0-1.155-.96l-3.75 4.5a.893.893 0 0 0-.067.112c0 .038 0 .06-.052.098A.75.75 0 0 0 0 6a.75.75 0 0 0 .053.27c0 .037 0 .06.052.097.02.04.042.077.067.113l3.75 4.5a.751.751 0 0 0 1.155-.96L2.355 6.75h8.895a.75.75 0 1 0 0-1.5Z"/>
                </svg>
                <h3>История изменений</h3>
            </div>
            <div className={s.title_history}>
                <p>Когда</p>
                <p>Категория</p>
                <p>Изменяемые поля</p>
                <p>Какие изменения произошли</p>
            </div>

            {data?.data.map(item => (
                <div className={s.item_history} key={uuid()}>
                    <p>{item.created_at}</p>
                    <div>
                    {item.categories.map((item) => (
                        <p key={item.alias}>{item.title}</p>
                    ))}
                    </div>
                    <div>
                    {[item.fields].map(field => (
                            field.seoTitle &&
                            <div className={s.history_flex} key={uuid()}>
                                <p>SEO Заголовок</p>
                                <p>{field.seoTitle?.old}</p>
                                <ArrowRight />
                                <p>{field.seoTitle?.new}</p>
                            </div>
                        ))}
                        {[item.fields].map(field => (
                            field.seoDescription &&
                            <div className={s.history_flex} key={uuid()}>
                                <p>SEO Описание</p>
                                <p>{field.seoDescription?.old}</p>
                                <ArrowRight />
                                <p>{field.seoDescription?.new}</p>
                            </div>
                        ))}
                        {[item.fields].map(field => (
                            field.title &&
                            <div className={s.history_flex} key={uuid()}>
                                <p>Название</p>
                                <p>{field.title?.old}</p>
                                <ArrowRight />
                                <p>{field.title?.new}</p>
                            </div>
                        ))}

                        {[item.fields].map(field => (
                            field.description &&
                            <div className={s.history_flex} key={uuid()}>
                                <p>Описание</p>
                                <p>{field.description?.old}</p>
                                <ArrowRight />
                                <p>{field.description?.new}</p>
                            </div>
                        ))}

                        {[item.fields].map(field => (
                            field.contact_cost &&
                            <div className={s.history_flex} key={uuid()}>
                                <p>Цена контакта</p>
                                <p>{field.contact_cost?.old}</p>
                                <ArrowRight />
                                <p>{field.contact_cost?.new}</p>
                            </div>
                        ))}

                        {[item.fields].map(field => (
                            field.order_cost &&
                            <div className={s.history_flex} key={uuid()}>
                                <p>Сумма отклика</p>
                                <p>{field.order_cost?.old}</p>
                                <ArrowRight />
                                <p>{field.order_cost?.new}</p>
                            </div>
                        ))}

                        {[item.fields].map(field => (
                            field.subtitle &&
                            <div className={s.history_flex} key={uuid()}>
                                <p>Подпись</p>
                                <p>{field.subtitle?.old}</p>
                                <ArrowRight />
                                <p>{field.subtitle?.new}</p>
                            </div>
                        ))}

                    </div>

                </div>
            ))}
        </div>
    )
}