import Loader from "../../../../components/loader/Loader";
import {url} from "../../../../store/host-store";
import {NavLink} from "react-router-dom";
import {EyeIcon} from "../../../../components/icons/EyeIcon";
import {useGetUserFromPartnerProgramProcessedQuery} from "../../../../store/redux/queries/partnerProgram/partner-program-admin-query";
import stub from '../images/avatar.svg'

export const ProgramPartnerUsersProcessed = () => {
    const { data: dataUsers, isLoading } =useGetUserFromPartnerProgramProcessedQuery()
  return(
      <div className="partner_program_">
          <div className="admin__list-titles admin_program_grid">
              <p>ID</p>
              <p>Фото</p>
              <p>Договор</p>
              <p>Сумма к выводу</p>
              <p>Статус заявки</p>
          </div>

          {isLoading ? <Loader/> :
              <>
                  {dataUsers?.data.map(item => (
                      <div key={item.id} className="admin__content-card program_content_grid">
                          <span># {item.user_id}</span>
                          {item.user?.avatar === null ?
                              <img className="program_partner_avatar_admin" src={stub} width={20} height={20} alt=""/>
                              : <img className="program_partner_avatar_admin" src={url + item.user.webpFilename} alt=""/>}
                          <a style={{justifySelf: "left"}} target="_blank" href='/' download={url + item.user.affiliateContract.file} >{item.user.affiliateContract.file}</a>
                          <p>{item.amount}</p>
                          <div className="program_flex_partner">
                              {item.confirmed === 1 && <p className="program_partner_accept_green">Одобрена</p>}
                              {item.confirmed === 0 && <p className="program_partner_accept_red">Отклонена</p>}
                          </div>
                          <NavLink to={`/admin/users/${item.user.id}`}>
                              <EyeIcon/>
                          </NavLink>
                      </div>
                  ))}
              </>
          }
      </div>
  )
}