import CitySelects from "../../../../cabinet/advertisement/create/CitySelects";
import {useEffect, useState} from "react";
import {link} from "../../../../store/host-store";
import {token} from "../../../../App";

const EditAdminPriceCity = () => {
    const [cityId, setCityId] = useState(0);
    const [price, setPrice] = useState('');
    const [newPrice, setNewPrice] = useState('');
    const [error, setError] = useState('');
    const [rerender, setRerender] = useState(false)

    const getCity = (city) => {
        setCityId(city.value)
        setNewPrice('')
    };

    useEffect(() => {
        fetch(`${link}/city/${cityId !== undefined ? cityId : JSON.parse(localStorage.getItem('location'))?.city_id}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            }
        })
            .then((res) => res.json())
            .then((res) => {
                setPrice(res.data?.ad_price)
            })
    }, [cityId, rerender])


    const changeCityPrice = () => {
        if(newPrice.length > 1){
            fetch(`${link}/city/${cityId}`, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({ad_price: +newPrice})
            })
                .then((res) => res.json())
                .then(() => {
                    setError('Сохранено')
                    setRerender(prevState => !prevState)
                })
        }else{
            setError('Цена остается прежней')
        }
    }

    return(
        <div className="edit_admin_city">
            <CitySelects getCity={getCity}/>
            <div className="edit_admin_city_inputs">
                <div className="block_ad_city_inputs">
                    <div className="info__block_title">Текущая цена</div>
                    <input type="number" defaultValue={price == null ? 100 : price} disabled/>
                </div>
                <div className="block_ad_city_inputs">
                    <div className="info__block_title">Новая цена</div>
                    <input type="number" value={newPrice} onChange={(event) => setNewPrice(event.target.value)}/>
                    {error.length > 0 && <p className="green">{error}</p>}
                </div>
            </div>
            <button type="button" className="admin__btn-blue admin__btn-blue_custom" onClick={changeCityPrice}>Сохранить</button>
        </div>
    )
}

export default EditAdminPriceCity;