export const mockData = [
    {
        date: "12.12.2024 10:28",
        category: "Техника " +
            "Мини-техника " +
            "Мини-погрузчики",
        fields: [
            {
                title: "SEO Заголовок",
                old: "Заголовок для категории на несколько строк",
                new: "Второй заголовок в который внесли изменения"
            },
            {
                title: "SEO Описание",
                old: "Тут описание на несколько строк точно поэтому тут нужен перенос",
                new: "Тут описание на несколько строк точно поэтому тут нужен перенос ЫЫЫЫЫ вот так вот даа"
            },
        ],
    },
    {
        date: "11.12.2024 10:28",
        category: "Техника " +
            "Лесная техника " +
            "Форвардеры",
        fields: [
            {
                title: "SEO Заголовок",
                old: "Заголовок для категории на несколько строк",
                new: "Второй заголовок в который внесли изменения"
            },
            {
                title: "SEO Описание",
                old: "Тут описание на несколько строк точно поэтому тут нужен перенос",
                new: "Тут описание на несколько строк точно поэтому тут нужен перенос ЫЫЫЫЫ вот так вот даа"
            },
        ],
    },
]

export const advData = [
    {
        id: 1,
        date: "12.12.2024 10:28",
        category: "Техника" +
            "Мини-техника" +
            "Мини-погрузчики",
        location: "Новосибирская область, Новосибирск",
        title: "Название объявления 1",
    },
    {
        id: 2,
        date: "12.12.2024 10:28",
        category: "Техника" +
            "Мини-техника" +
            "Мини-погрузчики",
        location: "Новосибирская область, Новосибирск",
        title: "Название объявления 1",
    },
    {
        id: 3,
        date: "12.12.2024 10:28",
        category: "Техника" +
            "Мини-техника" +
            "Мини-погрузчики",
        location: "Новосибирская область, Новосибирск",
        title: "Название объявления 1",
    },
]