import { useContext, useEffect } from "react";
import s from "./style.module.sass";

import { LogoIcon } from "../../../components/icons/LogoIcon";
import ModalsContext from "../../../store/modals-context";
import Loader from "../../../components/loader/Loader";
import getHost from "../../../store/host-store";
import { token } from "../../../App";

const LoadAuthorization = () => {
    const modal = useContext(ModalsContext);

    useEffect(() => {
        if (modal.loginPhone !== null) {
            const data = {
                phone: modal.loginPhone,
            };

            const request = setInterval(() => {
                fetch(
                    getHost({
                        controller: "user",
                        action: "mts-phone-login",
                    }),
                    {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            Authorization: token,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                    }
                )
                    .then((res) => res.json())
                    .then((result) => {
                        if (result.success) {
                            localStorage.setItem("token", result.data.access_token);
                            if (result.data.role === "moderator") {
                                window.location.replace("/moderator");
                            } else {
                                window.location.reload();
                            }
                        } else {
                            request();
                        }
                    });
            }, 1000);
        }
    }, [modal.loginPhone]);

    return (
        <div className={s.modal}>
            <div className={s.modal__top}>
                <div className={s.logo}>
                    <LogoIcon color={"#4088DD"} />
                </div>
                <h2 className={s.title}>Подтвердите вход</h2>
                <p className={s.descr}>На ваш телефон поступил запрос о подтверждении входа</p>
                <div className={s.loader}>
                    <Loader />
                </div>
            </div>
        </div>
    );
};

export default LoadAuthorization;